import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Loading from 'components/loading';
import { Toaster } from 'react-hot-toast';

const Home = lazy(() => import('app/home'));
const About = lazy(() => import('app/about'));
const Contact = lazy(() => import('app/contact'));
const Scan = lazy(() => import('app/scan'));
const Pricing = lazy(() => import('app/pricing'));
const Blog = lazy(() => import('app/blog'));
const Partner = lazy(() => import('app/partner'));
const Privacy = lazy(() => import('app/privacy'));
const PageNotFound = lazy(() => import('app/pageNotFound'));
const Terms = lazy(() => import('app/terms'));
const Accounting = lazy(() => import('app/accounting'));
const Business = lazy(() => import('app/business'));
const OrderManagement = lazy(() => import('app/order'));
const Payment = lazy(() => import('app/payment'));
const Storefront = lazy(() => import('app/storefront'));
const Barcode = lazy(() => import('app/barcode'));
const Purchase = lazy(() => import('app/purchase'));
const Stock = lazy(() => import('app/stock'));
const WarehouseCaseStudy = lazy(() => import('app/warehouseCaseStudy'));
// const WhiteLabel = lazy(() => import('app/whiteLabel'));
const Warehouse = lazy(() => import('app/warehouse'));

const App = () => {
  return (
    <Suspense fallback={<Loading />}>
      <BrowserRouter>
        <Toaster />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/scan" element={<Scan />} />
          <Route exact path="/pricing" element={<Pricing />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/partner" element={<Partner />} />
          <Route exact path="/privacy-policy" element={<Privacy />} />
          <Route exact path="/order" element={<OrderManagement />} />
          <Route exact path="/accounting" element={<Accounting />} />
          <Route exact path="/barcode" element={<Barcode />} />
          <Route exact path="/payment" element={<Payment />} />
          <Route exact path="/storefront" element={<Storefront />} />
          <Route exact path="/business" element={<Business />} />
          <Route exact path="/purchase" element={<Purchase />} />
          <Route exact path="/stock" element={<Stock />} />
          <Route exact path="/warehouseCaseStudy" element={<WarehouseCaseStudy />} />
          {/* <Route exact path="/white-label" element={<WhiteLabel />} /> */}
          <Route exact path="/warehouse-management" element={<Warehouse />} />
          <Route exact path="/terms-and-conditions" element={<Terms />} />
          <Route path="/404" element={<PageNotFound />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
