import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import logo from 'assets/navbar-logo.png';

const Loading = () => {
  return (
    <Box display="grid" placeItems={'center'} w="100%" h="100vh">
      <Image src={logo} alt="logo" objectFit="cover" />
    </Box>
  );
};

export default Loading;
