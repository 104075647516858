import { extendTheme } from '@chakra-ui/react';

export const Theme = extendTheme({
  styles: {
    global: () => ({
      body: {
        backgroundColor: '#fff',
        fontFamily: `'Satoshi', sans-serif`,
        color: '#060212',
      },
    }),
  },
  components: {
    Button: {
      baseStyle: {
        bg: '#3F12C4',
        color: '#fff',
        borderRadius: '6px',
      },
      variants: {
        solid: {
          bg: '#3F12C4',
          fontSize: '14px',
          fontWeight: '700',
        },
        outline: {
          bg: 'transparent',
          border: '1px solid',
          borderColor: '#3F12C4',
          color: '#3F12C4',
          borderRadius: '6px',
          fontSize: '14px',
          fontWeight: '500',
        },
      },
    },
    Text: {
      baseStyle: {
        fontFamily: `'Satoshi', sans-serif`,
        fontWeight: '500',
        color: '#060212',
      },
      variants: {
        heading2: {
          fontSize: '40px',
          fontWeight: '700',
          color: '#060212',
        },
        small: {
          color: '#060212CC',
          fontSize: '16px',
          fontWeight: '500',
        },
      },
    },
  },
});
